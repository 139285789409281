$(document).ready(function() {
  $('.contactoDiv input[type="checkbox"]').iCheck({
    checkboxClass: 'icheckbox_square-red',
    radioClass: 'iradio_square-red',
    increaseArea: '20%'
  });
   if($('body.index').length>0){
    $(window).scroll(function(event){
        //Sets the current scroll position
        var st = $(this).scrollTop();
        //Determines up-or-down scrolling
        if (st > ($('#carousel-home').offset().top+$('#carousel-home').height()-($('#header').height()+50))){
           $('.mainnav li:eq(0)').addClass("active");
        }
        else {
           $('.mainnav li:eq(0)').removeClass("active");

        }
        //Updates scroll position
    });
  }

  //add sticky
  var lastScrollTop = 0;
  $(window).scroll(function() {
    /*var winTop = $(this).scrollTop();
    if($('.header').offset().top > winTop){
      $('.header').removeClass('sticky');
    }else{
      $('.header').addClass('sticky');
    }*/





//Up or Down
$(function(){
  //Keep track of last scroll
  var lastScroll = 0;
  $(window).scroll(function(event){
      //Sets the current scroll position
      var st = $(this).scrollTop();
      //Determines up-or-down scrolling
      if (st > 0){
         //Replace this with your function call for downward-scrolling
         //console.log("DOWN");
         $('.header').addClass('sticky');
      }
      else {
         //Replace this with your function call for upward-scrolling
         //console.log("UP");
         $('.header').removeClass('sticky');

      }
      //Updates scroll position
      lastScroll = st;
  });
});

  });


  $(document).on('click', '[data-toggle="lightbox"]', function(event) {
    //console.log("test2");
                  event.preventDefault();
                  $(this).ekkoLightbox();
              });


   $('.fancybox').fancybox({
    infobar: false,
    //autoSize : true,
    //width    : "auto",
    //height   : "80%",
    preventCaptionOverlap:false
  });
   //http://fancyapps.com/fancybox/3/docs/#images
  $(document).on("click",".moreinfobut",function(){
    if($('.moreinfoText').is(":visible")){
      $('.moreinfobut').find("span").text("+");
      $('.moreinfoText').hide();
    }else{
      $('.moreinfoText').show();
      $('.moreinfobut').find("span").text("-");
    }
  })
  //$( "input[type='checkbox']" ).checkboxradio();
  //$( "#slider-filter" ).slider();

  /* CLick BTN (Open and Close) */
  $('.button-nav--toggle').on('click', function(e) {
    e.preventDefault();
    $("body").toggleClass("is-showNavMob");
  });

  var mySwiper = new Swiper ('.swiper-container', {

    loop: true,
    autoplay: {
      delay: 5000,
    },
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable:true
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    // And if we need scrollbar
    /*scrollbar: {
      el: '.swiper-scrollbar',
    },*/
  })
$(document).on("click",".btn-color",function(){
  $('.btn-color').removeClass("active");
  $(this).addClass("active");
  var boton_color = $(this).attr("data-id");
  $('.coloresT').hide();
  $('.color_'+boton_color).show();
})

});


