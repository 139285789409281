(function(){
     'use strict';

     angular
        .module('app')
        .factory('FiltroService', FiltroService);

    FiltroService.$inject = ['$http'];

    function FiltroService($http){


        var service= {
           getProducts:getProducts
        };

        return service;
 		function getProducts(params,callback)
        {
            var config = {
                headers : {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;'
                }
            }
            var data = $.param({
               params:params
            });

            var baseUrl = $('body').attr("data-baseurl");

            $http.post(baseUrl+'/ajax/getproducts', data, config)
            .success(getContentSuccess)
            .error(getContentFailed);
            function getContentSuccess(data){ 
                callback(data);
            }
            function getContentFailed(data){
                
                return false;
            }
        }
    }
})();