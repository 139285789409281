(function(){
    'use strict';
   
    angular
    .module('app')
    .controller('FiltroController', FiltroController)
 
    FiltroController.$inject = ["FiltroService","$http","$timeout","$log", "$document","$sce","$scope","$location"];
   
    function FiltroController(FiltroService,$http,$timeout, $log, $document,$sce,$scope,$location)
    {                  
        var vm = this; 
        vm.productos = [];
        vm.loading = true;
        vm.params = {};
        vm.filtrosActivos = false;
        vm.params.slidemin = 0;
        vm.params.slidemax = 0;
        vm.params.busqueda = "";
        vm.params.pagina = 0;
        vm.maxPag = 0;
        vm.params.tipoproducto = [];
        vm.params.materiales = [];
        vm.params.acabados = [];
        vm.params.bisagras = [];
        vm.params.caracteristicas = [];
        vm.view = "grid";
        vm.array = [];

        vm.comprobarFin = function(){
            var res = false
            for(var cont=0;cont<vm.array.length && !res;cont++){
                if(vm.array[cont] == vm.maxPag){
                    res = true;
                }
            }
            //console.log(vm.array);
            //console.log(vm.params.pagina+1);
            //console.log(res);
            return res;
        }

        vm.prevPag = function(){
            vm.params.pagina--;
            vm.getProductos();
        }
        vm.nextPag = function(){
            vm.params.pagina++;
            vm.getProductos();
        }


        vm.setPag = function(pag){
            vm.params.pagina = parseInt(pag);
            vm.getProductos();
        }
        vm.getNumber = function() {
            vm.array = [];
            if(vm.maxPag < (5-2)){
                for(var cont=0;cont<vm.maxPag;cont++){
                    vm.array.push(cont+1);
                }
            }else{
                if(vm.params.pagina<2){
                    for(var cont=0;cont<(5-2);cont++){
                        vm.array.push(cont+1);
                    }
                }else{
                    //console.log("AKI1");
                    if(vm.params.pagina>parseInt(vm.maxPag)-3){
                        //console.log("AKI2");
                        for(var cont=parseInt(vm.maxPag)-(5-2);cont<vm.maxPag;cont++){
                            vm.array.push(parseInt(cont)+1);
                        }
                    }else{
                        for(var cont=parseInt(vm.params.pagina)-1;cont<parseInt(vm.params.pagina)+2;cont++){
                            vm.array.push(parseInt(cont)+1);
                        }
                    }
                }
            }
        }
        vm.rellenarUrl = function(){
            var locationHref = window.location.href;
            var locationSplit = locationHref.split("-");
            for(var cont = 1;cont<locationSplit.length;cont++){
                var type = locationSplit[cont].split("=");
                var ids = type[1].split("_");
                
                switch(type[0]){
                    case "tprod":
                        for(var cont2=0;cont2<ids.length;cont2++){
                            $('#ch-'+ids[cont2]).prop("checked",true);
                        }
                        vm.params.tipoproducto = type[1].split("_");
                    break;
                    case "mat":
                        for(var cont2=0;cont2<ids.length;cont2++){
                            $('#ch-'+ids[cont2]).prop("checked",true);
                        }
                        vm.params.materiales = type[1].split("_");
                    break;
                    case "ac":
                        vm.params.acabados = type[1].split("_");
                    break;
                    case "car":
                        for(var cont2=0;cont2<ids.length;cont2++){
                            $('#ch-'+ids[cont2]).prop("checked",true);
                        }
                        vm.params.caracteristicas = type[1].split("_");
                    break;
                    case "bs":
                        for(var cont2=0;cont2<ids.length;cont2++){
                            $('#bs-'+ids[cont2]).prop("checked",true);
                        }
                        vm.params.bisagras = type[1].split("_");
                    break;
                    /*case "slid":
                        var slide = type[1].split("_");
                        vm.params.slidemin = slide[0];
                        vm.params.slidemax = slide[1];
                    break;*/
                    case "pag":
                        vm.params.pagina = type[1];
                    break;
                    case "sear":
                        //console.log(decodeURI(type[1]));
                        vm.params.busqueda = decodeURI(type[1]);
                    break;
                }
            } 
        }
        vm.rellenarUrl();

        vm.ponerUrls = function(){
            var params = [];
            var url = "";
            for(var cont=0;cont<vm.params.tipoproducto.length;cont++){
                params.push(vm.params.tipoproducto[cont]);
            }
            if(params.length > 0){
                url+="-tprod="+params.join("_");
            }
            var params = [];

            for(var cont=0;cont<vm.params.materiales.length;cont++){
                params.push(vm.params.materiales[cont]);
            }
            if(params.length > 0){
                url+="-mat="+params.join("_");
            }

            var params = [];

            for(var cont=0;cont<vm.params.bisagras.length;cont++){
                params.push(vm.params.bisagras[cont]);
            }
            if(params.length > 0){
                url+="-bs="+params.join("_");
            }
            var params = [];

            for(var cont=0;cont<vm.params.acabados.length;cont++){
                params.push(vm.params.acabados[cont]);
            }
            if(params.length > 0){
                url+="-ac="+params.join("_");
            }
            var params = [];

            for(var cont=0;cont<vm.params.caracteristicas.length;cont++){
                params.push(vm.params.caracteristicas[cont]);
            }
            if(params.length > 0){
                url+="-car="+params.join("_");
            }

            if(vm.params.pagina>0){
                url+="-pag="+vm.params.pagina;
            }

            if(vm.params.busqueda!=""){
                url+="-sear="+vm.params.busqueda;
            }

            /*if(params.length > 0){
                url+="-slid="+vm.params.slidemin+"-"+vm.params.slidemax;
            }*/
            window.history.pushState("", "", "#"+url);
            //console.log(url);
        }

        vm.filtrosAc = function(){
            if(vm.params.tipoproducto.length==0){
                $.fancybox.open({
                src  : '#hidden-content',
                type : 'inline',
                opts : {
                  afterShow : function( instance, current ) {
                    //console.info('done!');
                  }
                }
              });
            }else{
                vm.filtrosActivos = !vm.filtrosActivos;
            }
        }
        vm.getProductos = function(){
            
            vm.ponerUrls();
            FiltroService.getProducts(vm.params,function(data){
                vm.productos = data.productos;
                vm.loading = false;
                vm.maxPag = data.maxPag;
                vm.getNumber();
            });
        }

         vm.existe = function(id,array){
            var res = false;
            for(var cont=0;cont<array.length && !res;cont++){
                if(array[cont]==id){
                    res = cont;
                }
            }
            return res;
        }

        vm.estaTipo = function(id){
            var res = false;
            for(var cont=0;cont<vm.params.tipoproducto.length && !res;cont++){
                if(vm.params.tipoproducto[cont]==id){
                    res = true;
                }
            }
            return res;
        }

        vm.toggleTipoProducto = function(id){
            var contador = vm.existe(id,vm.params.tipoproducto)
            if (contador===false) {
              vm.params.tipoproducto.push(id);
            }else{
                vm.params.tipoproducto.splice(contador, 1);
            }

            if(!vm.existe(47,vm.params.tipoproducto)){
                $('.filtro input[type="checkbox"].bisagras:checked').each(function(){
                    $(this).click();
                })
                vm.params.bisagras = [];
            }

            if(vm.params.tipoproducto.length==0){
                vm.filtrosActivos = false;
                vm.limpiarFiltros();
            }
        }

        vm.toggleMateriales = function(id){
            var contador = vm.existe(id,vm.params.materiales)
            if (contador===false) {
              vm.params.materiales.push(id);
            }else{
                vm.params.materiales.splice(contador, 1);
            }
        }

        vm.toggleBisagras = function(id){
            var contador = vm.existe(id,vm.params.bisagras)
            if (contador===false) {
              vm.params.bisagras.push(id);
            }else{
                vm.params.bisagras.splice(contador, 1);
            }
        }

        vm.toggleAcabados = function(id){
            var contador = vm.existe(id,vm.params.acabados)
            if (contador===false) {
              vm.params.acabados.push(id);
            }else{
                vm.params.acabados.splice(contador, 1);
            }
        }

        vm.toggleCaracteristicas = function(id){
            var contador = vm.existe(id,vm.params.caracteristicas)
            if (contador===false) {
              vm.params.caracteristicas.push(id);
            }else{
                vm.params.caracteristicas.splice(contador, 1);
            }
        }

        vm.colorSelected = function(id){
            var contador = vm.existe(id,vm.params.acabados);
            //console.log(contador);
            var res = false;
            if (contador!==false) {
                res = true;
            }
            return res;
        }

        vm.tipoSelected = function(id){
            var contador = vm.existe(id,vm.params.tipoproducto);
            //console.log(contador);
            var res = false;
            if (contador!==false) {
                res = true;
            }
            return res;
        }

        vm.limpiarFiltros = function() {
            vm.params.pagina = 0;
            vm.params.tipoproducto = [];
            //vm.params.materiales = [];
            vm.params.acabados = [];
            //vm.params.caracteristicas = [];
            $timeout(function(){
                $('.filtro input[type="checkbox"]:checked').each(function(){
                    $(this).click();
                })
                vm.getProductos();

            })
        }

    }

})();