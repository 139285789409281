(function(){
    angular
        .module('app')
        .directive('ngSlider', slider);
        slider.$inject = ['$timeout','$document'];
        function slider($timeout,$document){
            return {
            	require: 'ngModel',
		        restrict: 'AE',
		        link: function(scope, element, attrs, ngModel) {
	                $timeout(function(){
	                	ngModel.$viewValue.slidemin = attrs.min;
						ngModel.$viewValue.slidemax = attrs.max;
	                })
			        	
	                var slider = element.slider({
	                    values: [attrs.min,attrs.max],
	                    range: true,
	                    min: parseFloat(attrs.min),
	                    max: parseFloat(attrs.max),
	                    //step: parseFloat(attrs.step),
	                    slide: function(event, ui) {
	                        scope.$apply(function() {
	                            //ngModel.$setViewValue(ui.value);
	                            ngModel.$viewValue.slidemin = ui.values[0];
	                            ngModel.$viewValue.slidemax = ui.values[1];
	                        });
	                    }
	                });

	                ngModel.$render = function () {
	                    var newValue = ngModel.$viewValue;
	                    element.slider('value', newValue);
	                };
		        }
		    }
       }
})();