(function(){
    angular
        .module('app')
        .directive('ngCheckbox', checkbox);
        checkbox.$inject = ['$timeout','$document'];
        function checkbox($timeout,$document){
            return {
		        restrict: 'A',
		        link: function(scope, element, attrs, ngModel) {
	                element.checkboxradio();
		        }
		    }
       }
})();